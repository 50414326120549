const navbarLinks = [
  {
    text: "Getting Started",
    type: "button",
    linkType: "inner",
    to: "/crok-documentation/tutorial"
  },

  {
    text: "Tools",
    type: "menu",
    menuItems: [
      {
        text: "CoBRA I/O",
        linkType: "inner",
        to: "/tools/cobra-io"
      },
      {
        text: "Timor-Python",
        linkType: "inner",
        to: "/tools/timor-python"
      },
      {
        text: "Robot Base Optimization",
        linkType: "inner",
        to: "/tools/rbo"
      },
      {
        text: "Holistic Modular Robot Optimization",
        linkType: "inner",
        to: "/tools/hmro"
      }
    ]
  },


  {
    text: "Documentation",
    type: "menu",
    menuItems: [
      {
        text: "Robot",
        linkType: "inner",
        /*to: "/model-cost-functions",*/
        to:"/crok-documentation/robot",
      },
      {
        text: 'Task',
        type: 'button',
        to:"/crok-documentation/scenario",
      },
      {
        text: 'Solution',
        type: 'button',
        to:"/crok-documentation/solution"
      },
      {
        text: 'Publications',
        type: 'button',
        to:"/publications"
      },
      {
        text: 'Tutorial',
        type: 'button',
        to: '/crok-documentation/tutorial'
      }
    ],
  },

  {
    text: "Tasks",
    type: "menu",
    menuItems: [
      {
        text: "Tasks List",
        linkType: "inner",
        to: "/tasks",
      },

      {
        text: "Ranking",
        linkType: "inner",
        to: "/solutions/ranking",
      },

     /* {
        text: "Challenges",
        linkType: "inner",
        to: "/challenges",
      },

      {
        text: "Scenario Repository",
        linkType: "outer",
        to: "https://gitlab.lrz.de/tum-cps/commonroad-scenarios",
      },

      {
        text: "Scenario Format Documentation 2020a (.pdf)",
        linkType: "outer",
        to: "https://gitlab.lrz.de/tum-cps/commonroad-scenarios/-/blob/master/documentation/XML_commonRoad_2020a.pdf",
      },

      {
        text: "XML Schema Definition 2020a (.xsd)",
        linkType: "outer",
        to:
          "https://gitlab.lrz.de/tum-cps/commonroad-scenarios/-/blob/master/documentation/XML_commonRoad_XSD_2020a.xsd",
      },

      */
    ],
  },

  {
    text: "Robots",
    type: "button",
    linkType: "inner",
    to: "/robots",
  },
  {
    text: "Community",
    type: "menu",
    linkType: "inner",
    menuItems: [
      {
        text: "Forum",
        linkType: "outer",
        to: process.env.REACT_APP_FORUM_URL,
      },

      {
        text: "Publications",
        linkType: "inner",
        to: "/publications",
      }
    ]
  },

  {
    text: "About",
    type: "button",
    linkType: "inner",
    to: "/about",
    /*
    menuItems: [
      {
        text: "Announcement: Commonroad Motion Planning Competition for Autonomous Vehicles (IV 2022)",
        type: "button",
        linkType: "inner",
        to: "/competition/2022/announcement",
      },
      {
        text: "Organizers",
        type: "button",
        linkType: "inner",
        to: "/competition/2022/organizers",
      },
      {
        text: "Newsletter",
        type: "button",
        linkType: "outer",
        to: "https://docs.google.com/forms/d/e/1FAIpQLSfr4p5VVKen-v-Jzg3G-NRn2oVYE8mQbhlh5c7HPJCRoUydCw/viewform",
      },
      {
        text: 'Archive 2021',
        type: 'menu',
        menuItems: [
          {
            text: "Announcement: Competition for Motion Planning of Autonomous Vehicles (ITSC 2021)",
            type: "button",
            linkType: "inner",
            to: "/competition/2021/announcement",
          },
          {
            text: "Rules and Details",
            type: "button",
            linkType: "inner",
            to: "/competition/2021/details",
          },
          {
            text: "Leaderboard Phase 1",
            type: "button",
            linkType: "inner",
            to: "/challenges/id/7b3332ab-116c-4095-9334-dbd857d89844",
          },
          {
            text: "Leaderboard Phase 2",
            type: "button",
            linkType: "inner",
            to: "/challenges/id/b4d727de-0283-43e6-834d-4f5a386f2cf8",
          },
          {
            text: "Organizers",
            type: "button",
            linkType: "inner",
            to: "/competition/2021/organizers",
          },
        ]
      },
    ],

     */
  },
/*  {
    text: 'Demo',
    type: 'button',
    linkType: 'inner',
    to: "/demo",
  },

  {
    text: 'Visualization',
    type: 'button',
    linkType: 'inner',
    to: "/visualization",

  },*/
];

export default navbarLinks;
